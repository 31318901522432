import {
  ENV_DEVELOPMENT,
  getConfig,
  isAcpApp,
  isReceiverApp,
  isSenderApp,
} from "core/model/config";
import { activateEnvSwitch, isProd } from "core/model/utils/featureFlags";
import { AppType, Env, EnvContext as EnvContextType } from "core/types";
import { BaseEventSchema } from "core/validationSchemas/tracking";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTracking } from "react-tracking";

const defaultEnvContext: EnvContextType = {
  app: -1 as AppType,
  config: null,
  env: "development",
  isAcpApp: false,
  isReceiverApp: false,
  isSenderApp: false,
  setEnv: () => {},
};

const EnvContext = createContext<EnvContextType>(defaultEnvContext);

export function EnvProvider({
  app,
  children,
}: {
  app: AppType;
  children: ReactNode;
}) {
  const defaultEnv = activateEnvSwitch
    ? window.localStorage.getItem("env") || process.env.ENV
    : process.env.ENV;

  const [env, setEnv] = useState<Env>((defaultEnv ?? "development") as Env);
  const { Track } = useTracking<DeepPartial<BaseEventSchema>>({
    platform_information: { env },
  });

  const saveEnv = useCallback((newEnv: Env) => {
    window.localStorage.clear();
    window.localStorage.setItem("env", newEnv);
    setEnv(newEnv);
  }, []);

  const config = useMemo(() => getConfig(env), [env]);

  return (
    <Track>
      <EnvContext.Provider
        value={{
          app,
          env,
          config,
          setEnv: saveEnv,
          isSenderApp: isSenderApp(app),
          isReceiverApp: isReceiverApp(app),
          isAcpApp: isAcpApp(app),
        }}
      >
        {children}
      </EnvContext.Provider>
    </Track>
  );
}

export function useEnvContext() {
  const context = useContext(EnvContext);
  if (!isProd && (!context || context === defaultEnvContext)) {
    console.error(
      "You're attempting to use useEnvContext outside EnvContext.Provider",
    );
  }
  return context;
}

export function TestEnvProvider({
  app,
  children,
}: {
  app: AppType;
  children: ReactNode;
}) {
  return (
    <EnvContext.Provider
      value={{
        app,
        config: getConfig(ENV_DEVELOPMENT),
        env: ENV_DEVELOPMENT,
        isAcpApp: isAcpApp(app),
        isReceiverApp: isReceiverApp(app),
        isSenderApp: isSenderApp(app),
        setEnv: () => {},
      }}
    >
      {children}
    </EnvContext.Provider>
  );
}
