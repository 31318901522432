export const gray = {
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#E0E0E0",
  400: "#CCCCCC",
  500: "#949494",
  600: "#6C6C6C",
  700: "#525252",
  800: "#3D3D3D",
  900: "#212121",
} as const;
